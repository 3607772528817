<template>
    <layout-full-width :title="$tc('workflow', 2)">
        <component-actions :action-filters="[]"
                           :bulk-actions="[]"
                           :general-search-fields="generalSearchFields"
                           :itemsPerPage="itemsPerPage"
                           :page="page"
                           :primary-actions="primaryActions"
                           :secondary-actions="[]"
                           :total="total"
                           @applyFilters="onApplyFilters"
                           @changeItemsPerPage="onChangeItemsPerPage"
                           @paginate="onChangePage"
                           @refresh="listWorkflows"/>

        <div class="row">
            <div class="col-12">
                <div class="table-responsive">
                    <table :class="{'table-hover': !loading.WORKFLOW_LIST}" class="table">
                        <thead>
                        <component-table-header :heading-columns="headingColumns"
                                                :select-all-status="getSelectAllStatus()"
                                                :show-checkbox="true"
                                                @sort="onSortWorkflows"
                                                @toggleCheckbox="toggleCheckbox"/>
                        </thead>
                        <tbody>
                        <template v-cloak v-if="!loading.WORKFLOW_LIST && workflows.list.length > 0">
                            <tr v-for="(workflow, i) in workflows.list"
                                :key="'workflow-' + i"
                                class="pointer"
                                @click="onClickWorkflow($event, workflow)">
                                <td class="align-middle">
                                    <button class="btn btn-checkbox" @click="toggleCheckbox(i)">
                                        <font-awesome-icon
                                            :class="workflow.selected ? 'text-primary' : ''"
                                            :icon="workflow.selected ? ['fas', 'check-square'] : ['far', 'square']"
                                            class="pe-none"/>
                                    </button>
                                </td>
                                <td class="align-middle">{{ workflow.workflowDescShort }}</td>
                                <td class="align-middle">{{ workflow.workflowDescLong }}</td>
                                <td class="align-middle">
                                    <template v-if="workflow.meta_update_dt">
                                        {{ workflow.meta_update_dt | timestamp }}
                                    </template>
                                    <template v-else>
                                        {{ workflow.meta_insert_dt | timestamp }}
                                    </template>
                                </td>
                            </tr>
                        </template>
                        <template v-cloak v-if="!loading.WORKFLOW_LIST && workflows.list.length === 0">
                            <tr>
                                <td colspan="99">
                                    <div
                                        class="py-3 text-primary d-flex align-items-center justify-content-center flex-column mb-0"
                                        role="alert">
                                        <font-awesome-icon icon="exclamation-triangle" size="3x"/>
                                        <div class="mt-2 fs-4">{{ $t('pages.workflows.no-workflows-found') }}</div>
                                    </div>
                                </td>
                            </tr>
                        </template>
                        <template v-if="loading.WORKFLOW_LIST">
                            <tr v-for="n in itemsPerPage" :key="'row-shimmer-' + n">
                                <td class="align-middle">
                                    <component-shimmer height="14px" style="margin: 4px 0;" width="14px"/>
                                </td>
                                <td class="align-middle">
                                    <component-shimmer/>
                                </td>
                                <td class="align-middle">
                                    <component-shimmer/>
                                </td>
                                <td class="align-middle">
                                    <component-shimmer/>
                                </td>
                            </tr>
                        </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <component-create-workflow @create="listWorkflows"/>

        <component-update-workflow :active="update"
                                   size="md"
                                   @close="update = false"
                                   @update="onUpdateWorkflow"/>
    </layout-full-width>
</template>

<script>
import LayoutFullWidth from "@/layouts/LayoutFullWidth";
import ComponentActions from '@/components/ui/ComponentActions.vue';
import ComponentShimmer from "@/components/ComponentShimmer";
import shouldOpenSlideIn from "@/lib/should-open-slide-in";
import ComponentUpdateWorkflow from "@/components/ComponentUpdateWorkflow";
import ComponentCreateWorkflow from "@/components/ComponentCreateWorkflow";
import ComponentTableHeader from '@/components/ui/ComponentTableHeader.vue';

export default {
    name: "PageDatabases",
    components: {
        ComponentCreateWorkflow,
        ComponentUpdateWorkflow,
        ComponentShimmer,
        LayoutFullWidth,
        ComponentActions,
        ComponentTableHeader
    },
    computed: {
        loading() {
            return {
                WORKFLOW_LIST: this.$store.state.loading.WORKFLOW_LIST
            }
        },
        workflows() {
            return this.$store.state.WORKFLOW.workflows
        }
    },
    data() {
        return {
            filter: {},
            order: {meta_insert_dt: "DESC"},
            page: 1,
            itemsPerPage: 10,
            total: 0,
            bulk: false,
            primaryActions: [
                {
                    text: this.$t('actions.new'),
                    icon: 'plus',
                    type: 'modal',
                    target: '#workflow-create-wizard'
                }
            ],
            generalSearchFields: ['gen_workflow_desc_short'],
            update: false,
            headingColumns: [
                {
                    title: this.$tc('name', 1),
                    sortField: 'gen_workflow_desc_long'
                },
                {
                    title: this.$tc('description', 1),
                    sortField: 'gen_workflow_desc_long'
                },
                {
                    title: this.$t('modified')
                },
            ]
        }
    },
    methods: {
        listWorkflows() {
            this.$store.dispatch('WORKFLOW/LIST', {
                filter: this.filter,
                order: this.order,
                page: this.page,
                itemsPerPage: this.itemsPerPage,
                limit: this.limit,
            })
                .then(workflows => {
                    this.total = workflows.total;
                });
        },
        onSortWorkflows(order) {
            this.order = order;
            this.listWorkflows();
        },
        onChangePage(page) {
            if (page <= 0) {
                return;
            }

            this.page = parseInt(page, 10);
            this.listWorkflows();
        },
        onChangeItemsPerPage(itemsPerPage) {
            if (this.itemsPerPage === itemsPerPage) {
                return;
            }

            this.page = 1;
            this.itemsPerPage = itemsPerPage;
            this.listWorkflows();
        },
        onApplyFilters(filters) {
            this.filter = filters;
            this.listWorkflows();
        },
        async toggleCheckbox(i) {
            if (i === 'ALL') {
                if (this.getSelectAllStatus()) {
                    this.workflows.list.forEach(workflow => workflow.selected = false);
                } else {
                    this.workflows.list.forEach(workflow => workflow.selected = true);
                }
            } else {
                this.workflows.list[i].selected = !this.workflows.list[i].selected;
            }

            this.$forceUpdate();
            this.getActionButton();
        },
        getSelectAllStatus() {
            const count = this.workflows.list.length;
            let selected = 0;

            this.workflows.list.forEach(workflow => {
                if (workflow.selected) {
                    selected += 1;
                }
            });

            return count === selected && count !== 0;
        },
        getActionButton() {
            let selected = 0;

            this.workflows.list.forEach(workflow => {
                if (workflow.selected) {
                    selected += 1;
                }
            })

            this.bulk = selected !== 0;
        },
        onClickWorkflow(event, workflow) {
            if (!shouldOpenSlideIn(event.target)) {
                return;
            }

            this.$store.commit('backdrop/show');
            this.update = true;
            this.$store.dispatch('WORKFLOW/GET', workflow.workflowUniqueId);
        },
        onUpdateWorkflow() {
            this.$store.dispatch('toast/success', {
                title: this.$tc('toast.success.success'),
                message: this.$tc('toast.success.workflow-updated')
            });
        },
    },
    beforeMount() {
        this.listWorkflows();
    }
}
</script>

<style lang="scss" scoped>
</style>
