<template>
    <component-slide-in :class="{ active }" @close="close" :size="size">

        <template v-if="!loading.WORKFLOW_GET && !loading.WORKFLOW_UPDATE && Object.keys(workflow).length > 0">
            <div class="d-flex align-items-center mb-3">
                <component-avatar>{{ workflow.workflowDescShort.substr(0, 1).toUpperCase() }}</component-avatar>
                <component-slide-in-title :editable="false"
                                          :subtitle="workflow.workflowDescLong"
                                          :title="workflow.workflowDescShort"
                                          :uuid="workflow.workflowUniqueId"
                                          class="ms-3 flex-grow-1"/>
            </div>

            <nav class="nav nav-tabs mb-3">
                <button id="nav-general-tab"
                        aria-controls="nav-general"
                        aria-selected="false"
                        class="nav-link active"
                        data-bs-target="#nav-general"
                        @click="size = ''"
                        data-bs-toggle="tab"
                        role="tab"
                        type="button">{{ $t('general') }}
                </button>
                <button class="nav-link"
                        id="nav-configuration-tab"
                        data-bs-toggle="tab"
                        @click="size = 'lg'"
                        data-bs-target="#nav-configuration"
                        type="button"
                        role="tab"
                        aria-controls="nav-configuration"
                        aria-selected="false">{{ $tc('configuration', 1) }}
                </button>
                <button id="nav-references-tab"
                        aria-controls="nav-references"
                        aria-selected="false"
                        class="nav-link"
                        data-bs-target="#nav-references"
                        @click="size = ''"
                        data-bs-toggle="tab"
                        role="tab"
                        type="button">{{ $tc('reference', 2) }}
                </button>
                <button id="nav-comments-tab"
                        aria-controls="nav-comments"
                        aria-selected="false"
                        class="nav-link"
                        data-bs-target="#nav-comments"
                        @click="size = ''"
                        data-bs-toggle="tab"
                        role="tab"
                        type="button">{{ $tc('comment', 2) }}
                </button>
                <button id="nav-activity-tab"
                        aria-controls="nav-activity"
                        aria-selected="false"
                        class="nav-link"
                        data-bs-target="#nav-activity"
                        @click="size = ''"
                        data-bs-toggle="tab"
                        role="tab"
                        type="button">{{ $tc('activity', 1) }}
                </button>
            </nav>

            <div id="nav-tabContent" class="tab-content">
                <div id="nav-general" aria-labelledby="nav-general-tab" class="tab-pane fade show active"
                     role="tabpanel">
                    <form @submit.prevent="onUpdateWorkflow">
                        <div class="row">
                            <div class="col-6">
                                <div class="mb-1">
                                    <label class="form-label small"
                                           for="workflow-desc-short">{{ $t('short-name') }}</label>
                                    <input id="workflow-desc-short"
                                           v-model="descShort"
                                           :aria-label="$t('short-name')"
                                           :class="{'is-invalid': this.$v.descShort.$invalid}"
                                           :disabled="!edit.flag"
                                           aria-describedby="workflow-desc-short"
                                           class="form-control form-control-sm"
                                           type="text">
                                    <div v-if="!this.$v.descShort.required" class="invalid-feedback">
                                        {{ $t('validation.error.required.desc-short') }}
                                    </div>
                                    <div v-if="!this.$v.descShort.maxLength" class="invalid-feedback">
                                        {{
                                            $t('validation.error.maxLength.desc-short', {count: $v.descShort.$params.maxLength.max})
                                        }}
                                    </div>
                                </div>
                                <div class="mb-1">
                                    <label class="form-label small"
                                           for="workflow-desc-long">{{ $tc('name', 1) }}</label>
                                    <input id="workflow-desc-long"
                                           v-model="descLong"
                                           :aria-label="$tc('name', 1)"
                                           :class="{'is-invalid': this.$v.descLong.$invalid}"
                                           :disabled="!edit.flag"
                                           aria-describedby="workflow-desc-long"
                                           class="form-control form-control-sm"
                                           type="text">
                                    <div v-if="!this.$v.descLong.maxLength" class="invalid-feedback">
                                        {{
                                            $t('validation.error.maxLength.desc-long', {count: $v.descLong.$params.maxLength.max})
                                        }}
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label class="form-label small"
                                           for="workflow-comment">{{ $tc('description', 1) }}</label>
                                    <textarea id="workflow-comment"
                                              v-model="comment"
                                              :aria-label="$tc('description', 1)"
                                              :class="{'is-invalid': this.$v.comment.$invalid}"
                                              :disabled="!edit.flag"
                                              aria-describedby="last-name"
                                              class="form-control form-control-sm"
                                              rows="6"
                                              type="text"></textarea>
                                    <div v-if="!this.$v.comment.maxLength" class="invalid-feedback">
                                        {{
                                            $t('validation.error.maxLength.comment', {count: $v.comment.$params.maxLength.max})
                                        }}
                                    </div>
                                </div>

                                <p>
                                    <button v-if="!edit.flag"
                                            class="btn btn-sm btn-outline-primary me-2"
                                            type="button"
                                            @click="onClickEdit">
                                        {{ $t('actions.edit') }}
                                    </button>
                                    <button v-if="edit.flag"
                                            :disabled="this.$v.$invalid"
                                            class="btn btn-sm btn-outline-success me-2">
                                        {{ $t('actions.update') }}
                                    </button>
                                    <button v-if="edit.flag"
                                            class="btn btn-sm btn-outline-danger"
                                            type="button"
                                            @click="edit.flag = false">
                                        {{ $t('actions.cancel') }}
                                    </button>
                                </p>
                            </div>
                        </div>
                    </form>
                </div>

                <div class="tab-pane fade" id="nav-configuration" role="tabpanel" aria-labelledby="nav-configuration-tab">
                    <div class="table-responsive">
                        <table class="table">
                            <tbody>
                                <tr>
                                    <td>
                                        <pre>{{ workflow.workflowConfig }}</pre>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div id="nav-references" aria-labelledby="nav-references-tab" class="tab-pane fade" role="tabpanel">
                    <div class="table-responsive">
                        <table class="table">
                            <tbody>
                            <tr>
                                <td class="border-bottom-0" colspan="99">
                                    <div
                                        class="py-3 text-primary d-flex align-items-center justify-content-center flex-column mb-0"
                                        role="alert">
                                        <font-awesome-icon icon="exclamation-triangle" size="3x"/>
                                        <div class="mt-2 fs-4">{{ $t('no-references-found') }}</div>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div id="nav-comments" aria-labelledby="nav-comments-tab" class="tab-pane fade" role="tabpanel">
                    <div class="table-responsive">
                        <table class="table">
                            <tbody>
                            <tr>
                                <td class="border-bottom-0" colspan="99">
                                    <div
                                        class="py-3 text-primary d-flex align-items-center justify-content-center flex-column mb-0"
                                        role="alert">
                                        <font-awesome-icon icon="exclamation-triangle" size="3x"/>
                                        <div class="mt-2 fs-4">{{ $t('no-comments-found') }}</div>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div id="nav-activity" aria-labelledby="nav-activity-tab" class="tab-pane fade" role="tabpanel">
                    <div class="list-group">
                        <div v-if="workflow.meta_update_dt" class="list-group-item">
                            <div class="d-flex w-100 justify-content-between align-items-center">
                                <p class="mb-0">{{ $t('activity-logs.workflow-updated') }}</p>
                                <small>{{ workflow.meta_update_dt | timestamp }}</small>
                            </div>
                        </div>
                        <div class="list-group-item">
                            <div class="d-flex w-100 justify-content-between align-items-center">
                                <p class="mb-0">{{ $t('activity-logs.workflow-created') }}</p>
                                <small>{{ workflow.meta_insert_dt | timestamp }}</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <template v-else>
            <div class="d-flex align-items-center mb-3">
                <component-shimmer class="rounded-circle d-block me-3" height="64px" width="64px"/>
                <div>
                    <component-shimmer class="mb-1"/>
                    <component-shimmer width="241.8px"/>
                </div>
            </div>

            <div class="d-flex mb-3">
                <component-shimmer class="me-2" height="37.2px" width="100px"/>
                <component-shimmer class="me-2" height="37.2px" width="100px"/>
                <component-shimmer class="me-2" height="37.2px" width="100px"/>
            </div>

            <div class="mb-3">
                <component-shimmer height="33.2px" width="162px"/>
            </div>

            <table class="table">
                <thead>
                <tr>
                    <th scope="col"></th>
                    <th scope="col">{{ $tc('salutation', 1) }}</th>
                    <th scope="col">{{ $tc('first-name', 1) }}</th>
                    <th scope="col">{{ $tc('last-name', 1) }}</th>
                    <th scope="col">{{ $tc('company', 1) }}</th>
                    <th scope="col">{{ $tc('street', 1) }}</th>
                    <th scope="col">{{ $tc('postcode', 1) }}</th>
                    <th scope="col">{{ $tc('city', 1) }}</th>
                    <th scope="col">{{ $tc('country', 1) }}</th>
                    <th scope="col">{{ $tc('email', 1) }}</th>
                    <th scope="col">{{ $tc('action', 1) }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="n in 10" :key="'row-shimmer-' + n">
                    <td class="align-middle">
                        <component-shimmer height="14px" style="margin: 4px 0;" width="14px"/>
                    </td>
                    <td class="align-middle">
                        <component-shimmer width="100%"/>
                    </td>
                    <td class="align-middle">
                        <component-shimmer width="100%"/>
                    </td>
                    <td class="align-middle">
                        <component-shimmer width="100%"/>
                    </td>
                    <td class="align-middle">
                        <component-shimmer width="100%"/>
                    </td>
                    <td class="align-middle">
                        <component-shimmer width="100%"/>
                    </td>
                    <td class="align-middle">
                        <component-shimmer width="100%"/>
                    </td>
                    <td class="align-middle">
                        <component-shimmer width="100%"/>
                    </td>
                    <td class="align-middle">
                        <component-shimmer width="100%"/>
                    </td>
                    <td class="align-middle">
                        <component-shimmer width="100%"/>
                    </td>
                </tr>
                </tbody>
            </table>
        </template>

    </component-slide-in>
</template>

<script>
import ComponentSlideIn from "@/components/ui/ComponentSlideIn";
import ComponentShimmer from "@/components/ComponentShimmer";
import ComponentAvatar from "@/components/ui/ComponentAvatar";
import ComponentSlideInTitle from "@/components/ui/ComponentSlideInTitle";
import {maxLength, required} from "vuelidate/lib/validators";

export default {
    name: "ComponentUpdateWorkflow",
    components: {ComponentSlideInTitle, ComponentAvatar, ComponentShimmer, ComponentSlideIn},
    computed: {
        v() {
            return this.$v;
        },
        loading() {
            return {
                WORKFLOW_GET: this.$store.state.loading.WORKFLOW_GET,
                WORKFLOW_UPDATE: this.$store.state.loading.WORKFLOW_UPDATE
            }
        },
        workflow() {
            return this.$store.state.WORKFLOW.workflow;
        },
        descShort: {
            get() {
                return this.edit.flag ? this.edit.descShort : this.workflow.workflowDescShort;
            },
            set(workflowDescShort) {
                this.edit.descShort = workflowDescShort;
            }
        },
        descLong: {
            get() {
                return this.edit.flag ? this.edit.descLong : this.workflow.workflowDescLong;
            },
            set(workflowDescLong) {
                this.edit.descLong = workflowDescLong;
            }
        },
        comment: {
            get() {
                return this.edit.flag ? this.edit.comment : this.workflow.workflowComment;
            },
            set(workflowComment) {
                this.edit.comment = workflowComment;
            }
        }
    },
    props: {
        active: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            size: '',
            edit: {
                flag: false,
                descShort: '',
                descLong: '',
                comment: ''
            }
        }
    },
    methods: {
        close() {
            this.$store.commit('backdrop/hide');
            this.$emit('close');
        },
        onUpdateWorkflow() {
            this.$store.dispatch('WORKFLOW/UPDATE', {
                workflowUniqueId: this.workflow.workflowUniqueId,
                workflowDescShort: this.descShort,
                workflowDescLong: this.descLong,
                workflowComment: this.comment,
                workflowStatus: this.workflow.workflowStatus,
                workflowConfig: this.workflow.workflowConfig
            })
                .then(workflow => {
                    this.edit.flag = false;
                    this.$emit('update', workflow);
                    this.$gtag.event('WORKFLOW_UPDATE');
                })
                .catch(error => {
                    this.$store.dispatch('toast/danger', {
                        title: this.$tc('toast.danger.error'),
                        message: error.response.data.message
                    });
                });
        },
        onClickEdit() {
            this.edit.descShort = this.workflow.workflowDescShort;
            this.edit.descLong = this.workflow.workflowDescLong;
            this.edit.comment = this.workflow.workflowComment;
            this.edit.flag = true;
        },
        closeOnEscape(e) {
            if (e.key === 'Escape' && this.active === true) {
                this.close();
            }
        }
    },
    validations: {
        descShort: {
            maxLength: maxLength(50),
            required
        },
        descLong: {
            maxLength: maxLength(255)
        },
        comment: {
            maxLength: maxLength(4000)
        },
    },
    watch: {
        active(newValue) {
            if (newValue === true) {
                window.addEventListener('keydown', this.closeOnEscape);
            } else {
                window.removeEventListener('keydown', this.closeOnEscape);
            }
        }
    }
}
</script>

<style scoped>

</style>
