<template>
    <div id="workflow-create-wizard"
         ref="createWorkflowWizard"
         aria-hidden="true"
         class="modal fade"
         role="dialog"
         tabindex="-1">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <form @submit.prevent="onSubmit">


                    <div class="modal-header justify-content-between">
                        <button v-if="steps.current > 1"
                                class="border-0 bg-transparent me-2"
                                @click="previousStep()">
                            <span class="sr-only">{{ $t('actions.back') }}</span>
                            <font-awesome-icon icon="arrow-left"/>
                        </button>
                        <h5 class="modal-title">{{ $t('modal.create-workflow.title') }}</h5>
                        <button :aria-label="$t('actions.close')"
                                class="btn-close"
                                data-bs-dismiss="modal"
                                type="button"></button>
                    </div>

                    <div class="modal-body position-relative">
                        <div class="px-2 pt-2 rounded" style="background-color: #d9e3bd">
                            <div class="row">
                                <div class="col-12 mb-3">
                                    <label class="form-label" for="workflow-desc-short">
                                        <strong>{{ $t('short-name') }}</strong>
                                    </label>
                                    <input id="workflow-desc-short"
                                           v-model="workflow.workflowDescShort"
                                           class="form-control"
                                           required="required"
                                           type="text">
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12 mb-3">
                                    <label class="form-label" for="workflow-desc-long">
                                        <strong>{{ $tc('name', 1) }}</strong>
                                    </label>
                                    <input id="workflow-desc-long"
                                           v-model="workflow.workflowDescLong"
                                           class="form-control"
                                           type="text">
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12 mb-3">
                                    <label class="form-label" for="workflow-comment">
                                        <strong>{{ $tc('description', 1) }}</strong>
                                    </label>
                                    <input id="workflow-comment"
                                           v-model="workflow.workflowComment"
                                           class="form-control"
                                           type="text">
                                </div>
                            </div>
                        </div>


                        <hr>

                        <div class="px-2 pt-2 rounded" style="background-color: #d9e3bd">
                            <div class="row">
                                <div class="col-12 mb-3">
                                    <label class="form-label" for="workflow-type">
                                        <strong>{{ $tc('type', 1) }}</strong>
                                    </label>
                                    <select id="workflow-type" v-model="workflow.workflowType" class="form-select">
                                        <option value="WORKFLOW_ONE_TIME">{{
                                                $t('types.workflow.WORKFLOW_ONE_TIME')
                                            }}
                                        </option>
                                        <option value="WORKFLOW_RECURRING">{{
                                                $t('types.workflow.WORKFLOW_RECURRING')
                                            }}
                                        </option>
                                        <option value="WORKFLOW_TRIGGER">{{
                                                $t('types.workflow.WORKFLOW_TRIGGER')
                                            }}
                                        </option>
                                    </select>
                                </div>
                            </div>


                            <div v-if="workflow.workflowType === 'WORKFLOW_RECURRING'" class="row">
                                <div class="col-6 mb-3">
                                    <label class="form-label" for="starting-from-date">Starting</label>
                                    <input id="starting-from-date"
                                           v-model="workflow.processScheduleStartDt"
                                           class="form-control"
                                           type="date">
                                </div>
                                <div class="col-6 mb-3">
                                    <label class="form-label" for="starting-from-time">at</label>
                                    <select id="starting-from-time" v-model="workflow.processScheduleStartTime"
                                            class="form-select">
                                        <option value="00:00">00:00</option>
                                        <option value="00:15">00:15</option>
                                        <option value="00:30">00:30</option>
                                        <option value="00:45">00:45</option>
                                        <option value="01:00">01:00</option>
                                        <option value="01:15">01:15</option>
                                        <option value="01:30">01:30</option>
                                        <option value="01:45">01:45</option>
                                        <option value="02:00">02:00</option>
                                        <option value="02:15">02:15</option>
                                        <option value="02:30">02:30</option>
                                        <option value="02:45">02:45</option>
                                        <option value="03:00">03:00</option>
                                        <option value="03:15">03:15</option>
                                        <option value="03:30">03:30</option>
                                        <option value="03:45">03:45</option>
                                        <option value="04:00">04:00</option>
                                        <option value="04:15">04:15</option>
                                        <option value="04:30">04:30</option>
                                        <option value="04:45">04:45</option>
                                        <option value="05:00">05:00</option>
                                        <option value="05:15">05:15</option>
                                        <option value="05:30">05:30</option>
                                        <option value="05:45">05:45</option>
                                        <option value="06:00">06:00</option>
                                        <option value="06:15">06:15</option>
                                        <option value="06:30">06:30</option>
                                        <option value="06:45">06:45</option>
                                        <option value="07:00">07:00</option>
                                        <option value="07:15">07:15</option>
                                        <option value="07:30">07:30</option>
                                        <option value="07:45">07:45</option>
                                        <option value="08:00">08:00</option>
                                        <option value="08:15">08:15</option>
                                        <option value="08:30">08:30</option>
                                        <option value="08:45">08:45</option>
                                        <option value="09:00">09:00</option>
                                        <option value="09:15">09:15</option>
                                        <option value="09:30">09:30</option>
                                        <option value="09:45">09:45</option>
                                        <option value="10:00">10:00</option>
                                        <option value="10:15">10:15</option>
                                        <option value="10:30">10:30</option>
                                        <option value="10:45">10:45</option>
                                        <option value="11:00">11:00</option>
                                        <option value="11:15">11:15</option>
                                        <option value="11:30">11:30</option>
                                        <option value="11:45">11:45</option>
                                        <option value="12:00">12:00</option>
                                        <option value="12:15">12:15</option>
                                        <option value="12:30">12:30</option>
                                        <option value="12:45">12:45</option>
                                        <option value="13:00">13:00</option>
                                        <option value="13:15">13:15</option>
                                        <option value="13:30">13:30</option>
                                        <option value="13:45">13:45</option>
                                        <option value="14:00">14:00</option>
                                        <option value="14:15">14:15</option>
                                        <option value="14:30">14:30</option>
                                        <option value="14:45">14:45</option>
                                        <option value="15:00">15:00</option>
                                        <option value="15:15">15:15</option>
                                        <option value="15:30">15:30</option>
                                        <option value="15:45">15:45</option>
                                        <option value="16:00">16:00</option>
                                        <option value="16:15">16:15</option>
                                        <option value="16:30">16:30</option>
                                        <option value="16:45">16:45</option>
                                        <option value="17:00">17:00</option>
                                        <option value="17:15">17:15</option>
                                        <option value="17:30">17:30</option>
                                        <option value="17:45">17:45</option>
                                        <option value="18:00">18:00</option>
                                        <option value="18:15">18:15</option>
                                        <option value="18:30">18:30</option>
                                        <option value="18:45">18:45</option>
                                        <option value="19:00">19:00</option>
                                        <option value="19:15">19:15</option>
                                        <option value="19:30">19:30</option>
                                        <option value="19:45">19:45</option>
                                        <option value="20:00">20:00</option>
                                        <option value="20:15">20:15</option>
                                        <option value="20:30">20:30</option>
                                        <option value="20:45">20:45</option>
                                        <option value="21:00">21:00</option>
                                        <option value="21:15">21:15</option>
                                        <option value="21:30">21:30</option>
                                        <option value="21:45">21:45</option>
                                        <option value="22:00">22:00</option>
                                        <option value="22:15">22:15</option>
                                        <option value="22:30">22:30</option>
                                        <option value="22:45">22:45</option>
                                        <option value="23:00">23:00</option>
                                        <option value="23:15">23:15</option>
                                        <option value="23:30">23:30</option>
                                        <option value="23:45">23:45</option>
                                    </select>
                                </div>

                                <div class="col-6 mb-3">
                                    <label class="form-label" for="repeat-every">Repeat every</label>
                                    <input id="repeat-every" v-model="workflow.processScheduleRepeatValue" class="form-control" step="1"
                                           type="Number">
                                </div>

                                <div class="col-6 mb-3">
                                    <label class="form-label" for="repeat-every-unit">&nbsp;</label>
                                    <select id="repeat-every-unit"
                                            v-model="workflow.processScheduleRepeatUnit"
                                            class="form-select">
                                        <option value="MINUTE">Minute</option>
                                        <option value="HOUR">Hour</option>
                                        <option value="DAY">Day</option>
                                        <option value="WEEK">Week</option>
                                        <option value="MONTH">Month</option>
                                    </select>
                                </div>

                                <div class="col-6 mb-3">
                                    <label class="form-label" for="ending-from-date">Ending</label>
                                    <input id="ending-from-date" v-model="workflow.processScheduleEndDt" class="form-control"
                                           type="date">
                                </div>
                                <div class="col-6 mb-3">
                                    <label class="form-label" for="ending-from-time">at</label>
                                    <select id="ending-from-time" v-model="workflow.processScheduleEndTime"
                                            class="form-select">
                                        <option value="00:00">00:00</option>
                                        <option value="00:15">00:15</option>
                                        <option value="00:30">00:30</option>
                                        <option value="00:45">00:45</option>
                                        <option value="01:00">01:00</option>
                                        <option value="01:15">01:15</option>
                                        <option value="01:30">01:30</option>
                                        <option value="01:45">01:45</option>
                                        <option value="02:00">02:00</option>
                                        <option value="02:15">02:15</option>
                                        <option value="02:30">02:30</option>
                                        <option value="02:45">02:45</option>
                                        <option value="03:00">03:00</option>
                                        <option value="03:15">03:15</option>
                                        <option value="03:30">03:30</option>
                                        <option value="03:45">03:45</option>
                                        <option value="04:00">04:00</option>
                                        <option value="04:15">04:15</option>
                                        <option value="04:30">04:30</option>
                                        <option value="04:45">04:45</option>
                                        <option value="05:00">05:00</option>
                                        <option value="05:15">05:15</option>
                                        <option value="05:30">05:30</option>
                                        <option value="05:45">05:45</option>
                                        <option value="06:00">06:00</option>
                                        <option value="06:15">06:15</option>
                                        <option value="06:30">06:30</option>
                                        <option value="06:45">06:45</option>
                                        <option value="07:00">07:00</option>
                                        <option value="07:15">07:15</option>
                                        <option value="07:30">07:30</option>
                                        <option value="07:45">07:45</option>
                                        <option value="08:00">08:00</option>
                                        <option value="08:15">08:15</option>
                                        <option value="08:30">08:30</option>
                                        <option value="08:45">08:45</option>
                                        <option value="09:00">09:00</option>
                                        <option value="09:15">09:15</option>
                                        <option value="09:30">09:30</option>
                                        <option value="09:45">09:45</option>
                                        <option value="10:00">10:00</option>
                                        <option value="10:15">10:15</option>
                                        <option value="10:30">10:30</option>
                                        <option value="10:45">10:45</option>
                                        <option value="11:00">11:00</option>
                                        <option value="11:15">11:15</option>
                                        <option value="11:30">11:30</option>
                                        <option value="11:45">11:45</option>
                                        <option value="12:00">12:00</option>
                                        <option value="12:15">12:15</option>
                                        <option value="12:30">12:30</option>
                                        <option value="12:45">12:45</option>
                                        <option value="13:00">13:00</option>
                                        <option value="13:15">13:15</option>
                                        <option value="13:30">13:30</option>
                                        <option value="13:45">13:45</option>
                                        <option value="14:00">14:00</option>
                                        <option value="14:15">14:15</option>
                                        <option value="14:30">14:30</option>
                                        <option value="14:45">14:45</option>
                                        <option value="15:00">15:00</option>
                                        <option value="15:15">15:15</option>
                                        <option value="15:30">15:30</option>
                                        <option value="15:45">15:45</option>
                                        <option value="16:00">16:00</option>
                                        <option value="16:15">16:15</option>
                                        <option value="16:30">16:30</option>
                                        <option value="16:45">16:45</option>
                                        <option value="17:00">17:00</option>
                                        <option value="17:15">17:15</option>
                                        <option value="17:30">17:30</option>
                                        <option value="17:45">17:45</option>
                                        <option value="18:00">18:00</option>
                                        <option value="18:15">18:15</option>
                                        <option value="18:30">18:30</option>
                                        <option value="18:45">18:45</option>
                                        <option value="19:00">19:00</option>
                                        <option value="19:15">19:15</option>
                                        <option value="19:30">19:30</option>
                                        <option value="19:45">19:45</option>
                                        <option value="20:00">20:00</option>
                                        <option value="20:15">20:15</option>
                                        <option value="20:30">20:30</option>
                                        <option value="20:45">20:45</option>
                                        <option value="21:00">21:00</option>
                                        <option value="21:15">21:15</option>
                                        <option value="21:30">21:30</option>
                                        <option value="21:45">21:45</option>
                                        <option value="22:00">22:00</option>
                                        <option value="22:15">22:15</option>
                                        <option value="22:30">22:30</option>
                                        <option value="22:45">22:45</option>
                                        <option value="23:00">23:00</option>
                                        <option value="23:15">23:15</option>
                                        <option value="23:30">23:30</option>
                                        <option value="23:45">23:45</option>
                                    </select>
                                </div>
                            </div>

                            <div v-if="workflow.workflowType === 'WORKFLOW_TRIGGER'" class="row">
                                <div class="col-12 mb-3">
                                    <label class="form-label" for="inbound-channel">
                                        <strong>Inbound channel</strong>
                                    </label>
                                    <select id="inbound-channel" v-model="workflow.channelUniqueId" class="form-select">
                                        <option v-for="channel in channels.list"
                                                :key="channel.channelUniqueId"
                                                :value="channel.channelUniqueId">
                                            {{ channel.channelDescShort }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <hr>
                        <div class="px-2 pt-2 rounded" style="background-color: #d9e3bd">
                            <div class="row">
                                <div class="col-12 mb-3">
                                    <label class="form-label" for="task">
                                        <strong>Task</strong>
                                    </label>
                                    <select id="task" class="form-select">
                                        <option value="PROCESS_CAMPAIGN">Process campaign</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 mb-3">
                                    <div class="col-12 mb-3">
                                        <label class="form-label" for="campaign">
                                            <strong>{{ $tc('campaign', 1) }}</strong>
                                        </label>
                                        <select id="campaign" v-model="workflow.campaignUniqueId" class="form-select">
                                            <option v-for="(campaign, i) in campaigns.list"
                                                    :key="'campaign-' + i"
                                                    :value="campaign.campaignUniqueId">
                                                {{ campaign.campaignDescShort }}
                                            </option>

                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr v-if="workflow.workflowType !== 'WORKFLOW_ONE_TIME'">

                        <div v-if="workflow.workflowType !== 'WORKFLOW_ONE_TIME'" class="px-2 pt-2 rounded"
                             style="background-color: #d9e3bd">
                            <div class="row">
                                <div class="col-12 mb-3">
                                    <label class="form-label" for="status">
                                        <strong>Workflow status</strong>
                                    </label>
                                    <select id="status" v-model="workflow.workflowStatus" class="form-select">
                                        <option value="WORKFLOW_ACTIVE">Active</option>
                                        <option value="WORKFLOW_INACTIVE">Inactive</option>
                                    </select>
                                </div>
                            </div>
                        </div>


                        <component-loading v-if="loading.WORKFLOW_CREATE" background-color="rgba(255,255,255,.6)"/>

                        <!--
                        <div id="workflow-steps" class="accordion mb-3">
                            <div class="accordion-item" v-for="(step, i) in workflow.steps"
                                 :key="'workflow-step-' + i">
                                <h2 class="accordion-header" :id="'accordion-heading-' + i">
                                    <button class="accordion-button"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            :data-bs-target="'#workflow-collapse-' + i"
                                            aria-expanded="true"
                                            :aria-controls="'workflow-collapse-' + i">
                                        {{ step.stepDescShort }}
                                    </button>
                                </h2>
                                <div :id="'workflow-collapse-' + i"
                                     class="accordion-collapse collapse"
                                     :aria-labelledby="'accordion-heading-' + i"
                                     data-bs-parent="#workflow-steps">
                                    <div class="accordion-body">
                                        <div class="mb-3">
                                            <label class="form-label">{{ $tc('name', 1) }}</label>
                                            <input class="form-control" type="text" v-model="step.stepDescShort">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        -->

                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-primary">Save</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import {v4 as v4uuid} from "uuid";
import closeModal from "@/lib/close-modal";
import ComponentLoading from "@/components/ui/ComponentLoading";

export default {
    name: "ComponentCreateWorkflow",
    components: {ComponentLoading},
    computed: {
        loading() {
            return {
                WORKFLOW_CREATE: this.$store.state.loading.WORKFLOW_CREATE
            }
        },
        campaigns() {
            return this.$store.state.CAMPAIGN.campaigns;
        },
        channels() {
            return this.$store.state.CHANNEL.channels;
        },
        calculatedProcessScheduleStart() {
            return this.workflow.processScheduleStartDt + ' ' + this.workflow.processScheduleStartTime;
        },
        calculatedProcessScheduleEnd() {
            return this.workflow.processScheduleEndDt + ' ' + this.workflow.processScheduleEndTime;
        }
    },
    data() {
        return {
            steps: {
                current: 1,
                max: 2
            },
            workflow: {
                workflowDescShort: '',
                workflowDescLong: '',
                workflowComment: null,
                workflowType: 'WORKFLOW_ONE_TIME',
                workflowStatus: 'WORKFLOW_ACTIVE',
                processScheduleStartDt: '',
                processScheduleStartTime: '',
                processScheduleEndDt: '',
                processScheduleEndTime: '',
                processScheduleRepeatUnit: '',
                processScheduleRepeatValue: '',
                channelUniqueId: '',
                campaignUniqueId: '',
                task: 'PROCESS_CAMPAIGN',
                steps: [
                    {
                        stepUniqueId: v4uuid(),
                        stepDescShort: this.$t('modal.create-workflow.first-step'),
                        stepType: "IMPORT",
                        stepParentUniqueId: '',
                        stepConditions: {}
                    }
                ]
            }
        }
    },
    methods: {
        nextStep() {
            ++this.steps.current
        },
        previousStep() {
            --this.steps.current;
        },
        getStepClass(step) {
            return {
                active: step === this.steps.current,
                pending: step > this.steps.current,
                complete: step < this.steps.current
            };
        },
        onSubmit() {
            this.$store.dispatch('WORKFLOW/CREATE', {
                workflowUniqueId: null,
                workflowDescShort: this.workflow.workflowDescShort,
                workflowDescLong: this.workflow.workflowDescLong ? this.workflow.workflowDescLong : null,
                workflowComment: this.workflow.workflowComment ? this.workflow.workflowComment : null,
                workflowType: this.workflow.workflowType,
                workflowStatus: this.workflow.workflowStatus,
                workflowConfig: {
                    processScheduleStartDt: this.workflow.workflowType === 'WORKFLOW_RECURRING' ? this.calculatedProcessScheduleStart : null,
                    processScheduleEndDt: this.workflow.workflowType === 'WORKFLOW_RECURRING' ? this.calculatedProcessScheduleEnd : null,
                    processScheduleRepeatUnit: this.workflow.workflowType === 'WORKFLOW_RECURRING' ? this.workflow.processScheduleRepeatUnit : null,
                    processScheduleRepeatValue: this.workflow.workflowType === 'WORKFLOW_RECURRING' ? [this.workflow.processScheduleRepeatValue] : null,
                    channelUniqueId: this.workflow.workflowType === 'WORKFLOW_TRIGGER' ? this.workflow.channelUniqueId : null,
                    steps: [
                        {
                            stepUniqueId: v4uuid(),
                            stepDescShort: this.workflow.task,
                            stepType: this.workflow.task,
                            stepParentUniqueId: "",
                            stepConditions: {},
                            stepConfig: {
                                campaignUniqueId: this.workflow.campaignUniqueId
                            },
                        }
                    ]
                },
            })
                .then(workflow => {
                    closeModal(this.$refs.createWorkflowWizard);
                    this.$emit('create', workflow);
                    this.$store.dispatch('toast/success', {
                        title: this.$t('toast.success.success'),
                        message: this.$tc('toast.success.workflow-created')
                    })
                });
        },
        addStep() {
            const lastStep = this.workflow.steps.slice(-1).pop();

            console.log(lastStep);

            this.workflow.steps.push({
                stepUniqueId: v4uuid(),
                stepDescShort: this.$t('modal.create-workflow.new-step'),
                stepType: "IMPORT",
                stepParentUniqueId: lastStep.stepUniqueId,
                stepConditions: {},
                stepConfig: {
                    object: null,
                }
            })
        }
    },
    beforeMount() {
        this.$store.dispatch('CAMPAIGN/LIST', {
            page: 1,
            limit: null,
            itemsPerPage: 100,
            filter: {},
            order: {meta_insert_dt: 'DESC'}
        });
        this.$store.dispatch('CHANNEL/LIST', {
            filter: {},
            order: {meta_insert_dt: 'DESC'},
            page: 1,
            itemsPerPage: null
        })
    }
}
</script>

<style lang="scss" scoped>
.modal-wrapper {
    width: 100%;
    height: 70vh;
    margin: 0 auto;
    position: relative;
    overflow: hidden;

    .step {
        transition: margin .3s ease-in-out;
        position: absolute;
        top: 10px;
        right: 0;
        left: 0;
        bottom: 0;
        width: 100%;

        &.active {
            margin-left: 0;
        }

        &.complete {
            margin-left: -100%;
        }

        &.pending {
            margin-left: 100%;
        }
    }
}
</style>